<template>
  <div>
    <el-upload class="upload-demo"
               disabled
               action=""
               ref="filesUpload"
               list-type="picture"
               :show-file-list="false"
               :on-preview="onPreviewHandler"
               :file-list="fileList"
               :http-request="()=>{}" >

    </el-upload>
    <div>
      <ul class="el-upload-list el-upload-list--picture is-disabled">
        <li tabindex="0"
            class="el-upload-list__item is-success"
            v-for="iter in fileList"
            :key="iter.url"
            @click="onPreviewHandler(iter)">
          <img v-show="isImage(iter.name)" style="object-fit: cover;" :src="iter.url" alt="" class="el-upload-list__item-thumbnail">
          <img v-show="!isImage(iter.name)" src="@/assets/img/file.png" alt="" class="el-upload-list__item-thumbnail">
          <a class="el-upload-list__item-name"><i class="el-icon-document"></i>{{ iter.name }}</a>
          <label class="el-upload-list__item-status-label"><i class="el-icon-upload-success el-icon-check"></i></label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {exportPicture, isImage} from "@/utils/common";
import helper from "@/utils/helper";

export default {
  name: 'FileList',
  props: {
    designData: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  data(){
    return {

    }
  },
  computed: {
    fileList(){
      return this.showFileList(this.designData)
    }
  },
  methods: {
    isImage,
    /**
     * 点击文件
     * @param file
     */
    onPreviewHandler(file){
      const { url, name } = file
      exportPicture(url, name)
    },
    /**
     *
     */
    showFileList(item){
      const filesStr = item?.files || ''
      const files = []
      filesStr.split(',').forEach(iter => {
        const name = ((iter || '').split('/') || []).reverse()[0]
        iter && files.push({
          name: name,
          url: helper.getSourceUri(iter),
          value: iter
        })
      })
      return files
    },
  }
}
</script>

<style scoped>

</style>