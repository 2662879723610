<template>
  <div class="page-container">
    <page-header v-if="!setId"></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-body" >
          <div class="descriptions" style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">基础信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">姓名</span>
                      <span class="descriptions-item-content">{{ farmer && farmer.name }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">电话号码</span>
                      <span class="descriptions-item-content">{{ farmer && farmer.phone }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">所在地区</span>
                      <span class="descriptions-item-content">
                        {{ farmer && [farmer.province, farmer.city, farmer.area].join('') }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">详细地址</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.address }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions" style="padding: 20px;" >
            <div class="descriptions-header">
              <div class="descriptions-title">设计信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="3">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">设计文件</span>
                      <span class="descriptions-item-content">
                        <file-list :design-data="designData"></file-list>
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">

                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="descriptions" style="padding: 20px;" >
            <div class="descriptions-header">
              <div class="descriptions-title">项目物料</div>
            </div>
            <div class="descriptions-view" >
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="3">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">发货清单</span>
                      <span class="descriptions-item-content" style="display: flex;flex-wrap: wrap;">
                        <file-list :design-data="{files: material.order}"></file-list>
                      </span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="descriptions" style="padding: 20px;" >
            <div class="descriptions-header">
              <div class="descriptions-title">施工验收</div>
            </div>
            <div class="descriptions-view" >
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">施工状态</span>
                      <span class="descriptions-item-content">
                          {{ constructionAcceptanceStatusMapper(metadata.state) }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"
                      v-if="metadata.state === 'FAIL'">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">审核不通过原因</span>
                      <span class="descriptions-item-content">
                          {{ metadata.examineMsg && metadata.examineMsg }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">施工人</span>
                      <span class="descriptions-item-content">
                          {{ user && user.name }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">打卡记录</span>
                      <span class="descriptions-item-content">
                        <el-button type="primary" size="mini" @click="dialogVisible=true">查看</el-button>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">开工时间</span>
                      <span class="descriptions-item-content">
                          {{ metadata.startWorkDate }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">结束时间</span>
                      <span class="descriptions-item-content">
                          {{ metadata.endWorkData }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="3">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">施工图片</span>
                      <span class="descriptions-item-content" style="display: flex;flex-wrap: wrap;">
                        <el-image style="width: 160px;height: 90px;margin-right: 10px;margin-bottom: 10px;"
                                  fit="cover"
                                  :preview-src-list="viewImages(metadata.workIngImg)"
                                  v-for="iter in viewImages(metadata.workIngImg)"
                                  :key="iter"
                                  :src="iter"></el-image>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="3">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">接地电阻测试照片</span>
                      <span class="descriptions-item-content" style="display: flex;flex-wrap: wrap;">
                        <el-image v-if="metadata.resistanceImg"
                                  fit="cover"
                                  style="width: 160px;height: 90px;margin-right: 10px;margin-bottom: 10px;"
                                  :preview-src-list="[getSourceUrl(metadata.resistanceImg)]"
                                  :src="getSourceUrl(metadata.resistanceImg)"></el-image>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="3">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">完工俯视图</span>
                      <span class="descriptions-item-content" style="display: flex;flex-wrap: wrap;">
                        <el-image v-if="metadata.overlookImg"
                                  fit="cover"
                                  style="width: 160px;height: 90px;margin-right: 10px;margin-bottom: 10px;"
                                  :preview-src-list="[getSourceUrl(metadata.overlookImg)]"
                                  :src="getSourceUrl(metadata.overlookImg)"></el-image>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="3">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">逆变器条形码</span>
                      <span class="descriptions-item-content" style="display: flex;flex-wrap: wrap;">
                        <el-image v-if="metadata.barCodeImg"
                                  fit="cover"
                                  style="width: 160px;height: 90px;margin-right: 10px;margin-bottom: 10px;"
                                  :preview-src-list="[getSourceUrl(metadata.barCodeImg)]"
                                  :src="getSourceUrl(metadata.barCodeImg)"></el-image>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="3">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">备注</span>
                      <span class="descriptions-item-content" style="display: flex;flex-wrap: wrap;">
                        {{ metadata.notes }}
                      </span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="clock-in">
            <el-dialog title="打卡记录"
                       :visible.sync="dialogVisible"
                       width="45%">
              <div>
                <div v-if="metadata">
                  <el-row>
                    <el-col :span="4">序号</el-col>
                    <el-col :span="6">签到人员</el-col>
                    <el-col :span="8">签到地点</el-col>
                    <el-col :span="6">签到时间</el-col>
                  </el-row>
                  <div style="max-height: 450px;overflow: auto;">
                    <el-row v-for="(iter, index) in metadata.locations"
                            :key="iter.id"
                            style="padding: 10px 0;">
                      <el-col :span="4">{{ index+1 }}</el-col>
                      <el-col :span="6">{{ (iter.user && iter.user.name) || '-' }}</el-col>
                      <el-col :span="8">{{ iter.name }}</el-col>
                      <el-col :span="6">{{ iter.time }}</el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button type="info" size="mini" @click="dialogVisible = false">关闭</el-button>
              </div>
            </el-dialog>
          </div>

          <div v-if="(metadata.state === 'EXAMINE' || metadata.state === 'EXAMINE_TWO') && checkPermission('ys:examine')"
               class="descriptions"
               style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">操作</div>
            </div>
            <div class="descriptions-view">
              <el-button v-if="developerCheck(metadata.state)"
                         type="primary" @click="examineInfo">验收审核</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import {getWorkIngById, workIngExamine} from "@/api/apis";
import {developerCheck, exportPicture, viewImages} from "@/utils/common";
import helper, {constructionAcceptanceStatusMapper} from "@/utils/helper";
import {examineMsg} from "@/pageInteraction/examine/ExamineMsg";
import checkPermission from "@/utils/checkPermission";
import FileList from "@/components/FileList";
export default {
  name: "AcceptanceInfo",
  components: {FileList, PageHeader},
  data(){
    return {
      getData: getWorkIngById,
      id: '',
      metadata: {},

      farmer: null,
      workOrder: null,
      roofTypeModel: null,
      user: null,
      designData: {},
      material: {},
      dialogVisible: false,
    }
  },
  props: {
    setId: {
      type: String,
      default(){
        return ''
      }
    }
  },
  computed: {
    thisId(){
      return this.setId || this.$route.params.id
    }
  },
  mounted() {
    this.id = this.thisId
    this.getInfo()
  },
  methods: {
    checkPermission,
    constructionAcceptanceStatusMapper,
    viewImages,
    getSourceUrl: helper.getSourceUri,
    developerCheck,
    /**
     * 验收
     */
    submit(){

    },
    /**
     * 点击文件
     * @param file
     */
    onPreviewHandler(file){
      const { url, name } = file
      exportPicture(url, name)
    },
    /**
     *
     */
    closePage(){
      this.$router.replace('/admin/acceptance')
    },
    /**
     * 审核
     */
    examineInfo(){
      examineMsg(info => {
        const params = {
          workIngId: this.id,
          examineMsg: info.msg,
          state: info.state,
        }

        const loading = this.$loading({ fullscreen: true })
        workIngExamine(params).then(() => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.closePage()
          }, 500)
        }).finally(() => {
          loading.close()
        })

      })
    },
    /**
     *
     */
    showFileList(item){
      const filesStr = item?.files || ''
      const files = []
      filesStr.split(',').forEach(iter => {
        const name = ((iter || '').split('/') || []).reverse()[0]
        iter && files.push({
          name: name,
          url: helper.getSourceUri(iter),
          value: iter
        })
      })
      return files
    },
    /**
     * 获取数据
     */
    getInfo(){
      if (this.id) {
        this.getData(this.id).then(res => {
          this.metadata = res
          //
          this.farmer = this.metadata?.material?.farmer
          this.workOrder = this.metadata?.workOrder
          this.roofTypeModel = this.metadata?.workOrder?.roofTypeModel
          this.user = this.metadata?.user
          this.designData = this.metadata?.material?.design?.designData
          this.material = this.metadata?.material
        })
      } else {
        this.$message.error('页面异常, 请返回重新进入')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>